import { render, staticRenderFns } from "./case_gov_hccg.vue?vue&type=template&id=5671278a&scoped=true&"
import script from "./case_gov_hccg.vue?vue&type=script&lang=js&"
export * from "./case_gov_hccg.vue?vue&type=script&lang=js&"
import style0 from "./case_gov_hccg.vue?vue&type=style&index=0&id=5671278a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5671278a",
  null
  
)

export default component.exports